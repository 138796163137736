import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ENV, ajax, apiURL, randomChars, convertToBase64 } from '../../../providers/helpers';
import { SFSE_COMPTABILITE_MAIL, REGULAR_SIGNUP_PRICE } from '../../../providers/global';
import { PurchaseService } from '../../../providers/purchase.service';
import { PdfGeneratorService } from '../../../providers/pdf-generator.service';


@Component({
  selector: 'app-adhesion-moral-form',
  templateUrl: './adhesion-moral-form.component.html',
  styleUrls: ['./adhesion-moral-form.component.css']
})
export class AdhesionMoralFormComponent implements OnInit {
  @Output() close = new EventEmitter<{}>();
  @Input() displayed: string;

  env = ENV;
  regularSignupPrice = REGULAR_SIGNUP_PRICE;
  adherent: any = {
    organization_id: '',
    social_status_id: '',
    annual_ca_id: '',
    is_scientific_society: 'non',
    activities_description: '',
    site_url: '',
    contact_name: '',
    contact_mail: '',
    billing_address: '',
    billing_contact_name: '',
    billing_contact_phone: '',
    billing_contact_mail: ''
  };

  social_status_name: string;
  annual_ca_name: string;

  adherentOrganizationName: string;

  numberOfAdhesionOffered: number;
  numberOfExtraAdhesion = 0;
  adhesionCost: number;
  adhesionAmount: number;

  promoCode: string;

  socialStatuses = [];
  annualCas = [];
  annualCasFiltered = [];
  paiement_modes = [];

  thematics = [];
  selectedThematics = [];

  showThematics = false;

  showPopup = false;
  popupText = "";
  canClick;

  promoCodeHasBeenTested = false;
  formIsComplete = false;

  paiement_mode_id = -1; // paiement_mode_id option must be selected;
  pdf_name = "";
  pdf_names = "";
  pdf_datas = "";

  purchase_number: string;


  constructor(private purchaseService: PurchaseService, private pdfGeneratorService: PdfGeneratorService) { }

  sendMail(pdfData, promoCodeAdhesion, promoCodeScientificSociety, numberOfOfferedAdhesions) {
    // this.popupText = "";
    // this.showPopup = false;
    let subject = "Confirmation de l'adhesion personne morale à la SFSE",
      contactAndBillingContactHaveDifferentMails = false,
      addresses = `${this.adherent.contact_mail}`;
    
    if (this.adherent.billing_contact_mail && this.adherent.billing_contact_mail !== this.adherent.contact_mail) {
      addresses += `,${this.adherent.billing_contact_mail}`;
      contactAndBillingContactHaveDifferentMails = true;
    }

    let pdfDatas = pdfData;
    let pdfNames = 'adhesion_sfse';
    if(this.paiement_mode_id == 2 && this.pdf_datas) {
      pdfDatas += `__separator__${this.pdf_datas}`;
      pdfNames += `,${this.pdf_names}`;
    }

    const data: any = {
      body: 'adhesion_morale',
      subject,
      addresses,
      pdfDatas,
      pdfNames,
      promoCodeAdhesion,
    };

    if (this.env == "prod") {
      data.addComptaInCopy = true;
      data.isSentByCompta = true;
    }

    if (numberOfOfferedAdhesions > 0) {
      data.numberOfOfferedAdhesions = numberOfOfferedAdhesions;
    }
    if (promoCodeScientificSociety) {
      data.promoCodeScientificSociety = promoCodeScientificSociety;
    }

    const url = apiURL('-mailer_service', 'phpmailer', '');
    ajax(url, 'POST', data, (res: any) => {
      this.popupText += "<p>Un mail de confirmation vous a été envoyé à l'adresse " + this.adherent.contact_mail;
      if(contactAndBillingContactHaveDifferentMails) {
        this.popupText += " et à l'adresse " + this.adherent.billing_contact_mail + "</p>";
      } else {
        this.popupText += "</p>";
      }
      this.showPopup = true;
      this.formIsComplete = true;
    }, false);
  }

  closePopupAndWindow() {
    window.scroll(0, 0);
    this.showPopup = null
    if (this.formIsComplete == true) {
      this.closePopup();
    }
  }

  generatePdfFactureAdhesionMoral(billingNumber, adhesion_promo_code_id, scientific_promo_code_id, numberOfAdhesionsUsable) {
    const infos = {
      address: this.adherent.billing_address,
      billingNumber,
      moralPersonPricing: this.adhesionCost,
      physicalPersonPricing: this.regularSignupPrice,
      physicalPersonNbr: this.numberOfExtraAdhesion,
      socialStatus: this.social_status_name,
      annualCa: this.annual_ca_name,
      adhesionIsOffered: this.promoCodeHasBeenTested,
      adhesion_promo_code_id, 
      scientific_promo_code_id, 
      numberOfAdhesionsUsable,
      purchase_number: this.purchase_number
    };    

    const pdfDocGenerator = this.pdfGeneratorService.generatePdf('facture-adhesion-moral', infos, 'send');
    pdfDocGenerator.getBase64(data => {
      this.sendMail(data, adhesion_promo_code_id, scientific_promo_code_id, numberOfAdhesionsUsable);
    });
  }

  closePopup() {
    this.close.emit();
  }

  setOrganizationSuggestion(evt) {
    this.adherentOrganizationName = evt.name;
    this.adherent.organization_id = evt.id;
  }

  onSocialStatusChange(social_status) {
    this.adherent.annual_ca_id = null;
    this.adhesionAmount = 0;
    this.numberOfAdhesionOffered = null;
    this.numberOfExtraAdhesion = 0;
    this.social_status_name = social_status.name;
    if (social_status.id == 1 && this.adherent.is_scientific_society == 'oui') {
      this.calculateNumberOfAdhesionOffered();
    } else {
      this.adherent.is_scientific_society = 'non';
    }
    if (social_status.id != 3) {
      this.filterAnnualCas(social_status.id);
      // console.log(social_status.id);

    } else {
      this.annualCasFiltered = [];
      this.calculateNumberOfAdhesionOffered();
    }

  }

  onIsScientificSocietyChange() {
    this.numberOfExtraAdhesion = 0;
    if (this.adherent.is_scientific_society == 'oui') {
      this.popupText = `En tant que société savante, vous bénéficierez d'un nombre illimité d'adhésions annuelles, à distribuer à des personnes physiques.
      Vous recevrez donc, après inscription, un mail contenant un code promotionnel à usage illimité sur une durée de 1 an, 
      permettant l'adhésion de personnes physiques à la SFSE, à tarif préférentiel (45€).`;
      this.showPopup = true;
      this.calculateNumberOfAdhesionOffered();
    } else {
      this.adherent.annual_ca_id = null;
      this.adhesionAmount = 0;
      this.numberOfAdhesionOffered = null;
    }
  }

  requiredFieldsMissing() {
    const requiredFields = [
      "Statut", "Description des activités", "Nom de contact", "Email de contact", "Adresse de facturation",
      "Nom de contact (facturation)", "Email de contact (facturation)", "Téléphone (facturation)"
    ];

    const missings = [];

    ['social_status_id', 'activities_description', 'contact_name',
      'contact_mail', 'billing_address', 'billing_contact_name',
      'billing_contact_mail', 'billing_contact_phone'].forEach((field, i) => {
        if (!this.adherent[field]) {
          missings.push(requiredFields[i]);
        }
      });
      
      if (this.paiement_mode_id == -1 && this.adhesionAmount > 0) {
        missings.push( "Mode de paiement");
      } else if (this.paiement_mode_id == 2) {
          if(!this.pdf_datas) {
            missings.push("Ajout d'un PDF contenant votre bon de commande");
          }
          if (!this.purchase_number) {
             missings.push("Ajout d'un bon de commande");
          }
      }
    return missings;
  }

  onAnnualCaChange(annual_ca_name) {
    this.annual_ca_name = annual_ca_name;

    this.numberOfExtraAdhesion = 0;
    if (this.adherent.is_scientific_society == 'non') {
      this.calculateNumberOfAdhesionOffered();
    }
  }

  onNumberOfExtraAdhesionChange() {
    if (this.numberOfExtraAdhesion < 0 || this.numberOfExtraAdhesion > 12) {
      this.numberOfExtraAdhesion = null;
    }
    this.adhesionAmount = this.purchaseService.addExtraAdhesion(this.numberOfExtraAdhesion);
  }

  filterAnnualCas(evt) {
    if (evt == 1) {
      this.annualCasFiltered = this.annualCas.filter(ca => ca.id <= 2);
    } else if (evt == 2 || evt == 4) {
      this.annualCasFiltered = this.annualCas.filter(ca => ca.id > 2);
    } else {
      this.annualCasFiltered = [];
    }
  }

  calculateNumberOfAdhesionOffered() {
    if (this.adherent.is_scientific_society == 'oui') {
      this.numberOfAdhesionOffered = 1;
    } else if (this.adherent.social_status_id == 1) {
      if (this.adherent.annual_ca_id == 1) {
        this.numberOfAdhesionOffered = 2
      } else if (this.adherent.annual_ca_id == 2) {
        this.numberOfAdhesionOffered = 3;
      } else { return; }
    } else if (this.adherent.social_status_id == 2) {
      if (this.adherent.annual_ca_id == 3) {
        this.numberOfAdhesionOffered = 2
      } else if (this.adherent.annual_ca_id == 4) {
        this.numberOfAdhesionOffered = 3;
      } else { alert('Une erreur est survenue'); }
    } else if (this.adherent.social_status_id == 3) {
      this.numberOfAdhesionOffered = 3;
    } else if (this.adherent.social_status_id == 4) {
      this.numberOfAdhesionOffered = 1;
    } else { alert('Une erreur est survenue'); }

    this.purchaseService.calculateMoralAdhesionAmount(
      this.adherent.social_status_id,
      this.adherent.annual_ca_id,
      this.numberOfExtraAdhesion,
      this.promoCode,
      this.adherent.is_scientific_society).then((response: any) => {
        this.adhesionAmount = response.total;
        this.adhesionCost = response.adhesionCost;
        // console.log(response);
        if (response.promoCodeHasBeenTested) {
          alert('Code promotionnel validé !');
          this.promoCodeHasBeenTested = true;
        }
      });
  }

  selectThematic(thematic) {
    if (this.selectedThematics.findIndex(item => item.id == thematic.id) < 0) {
      this.selectedThematics.push(thematic);
    }
  }

  clearThematics() {
    this.selectedThematics = [];
  }

  loadThematics() {
    const url = apiURL('thematic', 'lire', '');
    ajax(url, 'GET', null, (res: any) => {
      this.thematics = JSON.parse(res).records || [];
    }, false);
  }

  createCodePromo(type_id, remaining) {
    return new Promise((resolve, reject) => {
      const id_ = randomChars(15);
      const promoCodeUrl = apiURL('promo_code', 'create', '');
      ajax(promoCodeUrl, 'POST', { remaining, type_id, user_moral_name: this.adherentOrganizationName, id_ }, (res: any) => {
        const promo_code_id_ = JSON.parse(res).records.id_;
        if (!promo_code_id_) reject(res);

        resolve(promo_code_id_);

      }, false, err => { reject(err) });
    });
  }

  createMoralAdhesion(billing_number, moral_person_id) {
    return new Promise((resolve, reject) => {

      const data = {
        billing_number,
        moral_person_id,
        extra_adhesion_physic_nbr: this.numberOfExtraAdhesion,
        pricing: this.adhesionCost,
        total_amount: this.adhesionAmount,
        paiement_mode_id: this.paiement_mode_id,
        purchase_number: this.purchase_number
      };

      const moralPersonAdhesionUrl = apiURL('adhesion_moral', 'create', '');
      ajax(moralPersonAdhesionUrl, 'POST', data, (res: any) => {
        if (JSON.parse(res).records) {
          if(this.promoCodeHasBeenTested && this.promoCode) {
            this.purchaseService.checkPromoCode(this.promoCode, 'adhesion_moral', 1).then(res2 => {
              // console.log("Code promotionnel utilisé : " + this.promoCode, res2);
              resolve('success');
            })
          } else {
            resolve('success');
          }
          
        } else {
          reject();
        }
      }, false, err => {
        reject(err);
      });
    });
  }

  createMoralPersonThematics(moral_person_id) {
    return new Promise((resolve, reject) => {
      if (this.selectedThematics.length < 1) {
        resolve('success');
        return;
      }

      const userThematicsData = {
        thematic_ids: this.selectedThematics.map(item => item.id).join(','),
        moral_person_id
      }

      // console.log(userThematicsData);

      const userThematicsUrl = apiURL('moral_person_thematic', 'create', '');
      ajax(userThematicsUrl, 'POST', userThematicsData, (res: any) => {
        // console.log(res);
        if (JSON.parse(res).status == "success") {
          resolve('success');
        } else {
          reject();
        }
      }, false, err => {
        reject(err);
      });
    });
  }

  createOrganization() {
    return new Promise((resolve, reject) => {
      const url = apiURL('organization', 'create', '');
      ajax(url, 'POST', { name: this.adherentOrganizationName }, (res: any) => {
        resolve(JSON.parse(res).records.id);
      }, false, err => {
        reject(err);
      });
    });
  }

  async register() {
    if (!this.canClick) {
      return;
    }

    const missingFields = this.requiredFieldsMissing();
    if (missingFields.length > 0) {
      this.popupText = "<h2>Liste des champs manquants</h2>"
      missingFields.forEach(field => {
        this.popupText += `${field}<br>`
      });
      this.showPopup = true;
      return;
    }

    if (!/[0-9]{1,4}\s[0-9]{8,12}/.test(this.adherent.billing_contact_phone)) {
      this.popupText = 'Le numéro de téléphone de contact doit respecter le modèle suivant : 33 012345678 (indicatif - espace - numéro de téléphone)';
      this.showPopup = true;
      return;
    }

    this.canClick = false;

    if (this.adherentOrganizationName && !this.adherent.organization_id) {
      this.adherent.organization_id = await this.createOrganization();
    }

    const moralPersonUrl = apiURL('moral_person', 'create', '');
    ajax(moralPersonUrl, 'POST', this.adherent, async (res: any) => {
      try {
        const moral_person_id = JSON.parse(res).records;
        if (!moral_person_id) return;

        const billing_number = this.purchaseService.generateBillNumber('adhesion_moral', null);

        const moralPersonWasCreated = await this.createMoralAdhesion(billing_number, moral_person_id);

        if (moralPersonWasCreated !== 'success') return;

        const moralPersonhematicsWereCreated = await this.createMoralPersonThematics(moral_person_id)

        if (moralPersonhematicsWereCreated !== 'success') return;

        let scientific_promo_code_id, adhesion_promo_code_id;

        if (this.adherent.is_scientific_society == 'oui') {
          const type_id = 2, remaining = 200;
          scientific_promo_code_id = await this.createCodePromo(type_id, remaining);
        }

        let numberOfAdhesionsUsable = this.numberOfAdhesionOffered + this.numberOfExtraAdhesion;
        if (numberOfAdhesionsUsable > 0) {
          const type_id = 1;
          adhesion_promo_code_id = await this.createCodePromo(type_id, numberOfAdhesionsUsable);
        }
        this.generatePdfFactureAdhesionMoral(billing_number, adhesion_promo_code_id, scientific_promo_code_id, numberOfAdhesionsUsable);
        this.popupText = "Votre adhésion en tant que personne morale est en attente de validation."
      } catch (error) {
        // console.log(error);
      }

    }, false, err => { console.log(err); })
  }

  async pdfHandler(e) {
    this.pdf_names = '';
    const pdfFile = e.target.files[0];
    this.pdf_name = pdfFile.name;
    this.pdf_datas = await convertToBase64(pdfFile);
    this.pdf_names = 'bon_de_commande';
  }

  FILL_FORM_FOR_TESTING() {
    this.adherent = {
      organization_id: '6',
      social_status_id: '3',
      annual_ca_id: '',
      is_scientific_society: 'non',
      activities_description: 'Test Description de l\'activité',
      site_url: 'https://google.fr',
      contact_name: 'Matthieu Bailly',
      contact_mail: 'brunoher@wanadoo.fr',
      billing_address: 'Université Paris Cité Bâtiment Buffon – 4, rue Marie-Andrée Lagroua Weill-Hallé – case 7073 – 75205 Paris cedex 13',
      billing_contact_name: 'Test NomDeContact',
      billing_contact_phone: '33 0625577235',
      billing_contact_mail: 'brunoher@wanadoo.fr'
    };

    this.purchase_number = "TEST-1234";
    this.adhesionAmount = 600;
    this.adhesionCost = 600;
    this.numberOfAdhesionOffered = 3;
    this.paiement_mode_id = 1;
  }

  ngOnDestroy() {
    document.body.style.overflow = "auto";
    this.purchaseService.reset();

  }

  ngOnInit() {
    // this.FILL_FORM_FOR_TESTING();
    this.promoCodeHasBeenTested = false;

    document.body.style.overflow = "hidden";
    window.scroll(0, 0);
    this.canClick = true;
    const socialStatusUrl = apiURL('social_status', 'lire', '');

    ajax(socialStatusUrl, 'GET', null, res => {
      let response: any = res;
      response = JSON.parse(response);
      this.socialStatuses = response.records;
    }, false);

    const annualCaUrl = apiURL('annual_ca', 'lire', '');

    ajax(annualCaUrl, 'GET', null, res => {
      let response: any = res;
      response = JSON.parse(response);
      this.annualCas = response.records;
    }, false);

    this.paiement_modes = [
      {
        id: 2,
        name: 'Bon de commande puis virement bancaire'
      },
      {
        id: 3,
        name: 'Virement simple'
      }
    ]

    this.loadThematics();
  }

}
