import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Observable, Subscription } from "rxjs";
import { ajax, apiURL } from "../../../providers/helpers";

@Component({
  selector: "app-pagination",
  templateUrl: "./pagination.component.html",
  styleUrls: ["./pagination.component.css"],
})
export class PaginationComponent implements OnInit, OnDestroy {
  eventsSubscription: Subscription;
  @Input() events: Observable<void>;
  @Input() perPage: number;
  @Input() table_name: string;
  @Input() data: any;
  @Output() output = new EventEmitter<{}>();

  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;

  pages = [];
  total: number;
  selectedPage;

  constructor() {}

  paginate(total) {
    this.pages = [];
    if (total < 1) {
      return;
    }

    let startIndex = 0;
    const perPage = Number(this.perPage);
    const filter_prop_name =
      window.sessionStorage.SFSE_filter_prop_name && this.data.active_filters
        ? window.sessionStorage.SFSE_filter_prop_name
        : null;
    const filter_prop_values =
      window.sessionStorage.SFSE_filter_prop_values && this.data.active_filters
        ? JSON.parse(window.sessionStorage.SFSE_filter_prop_values)
            .map((item) => item.id)
            .join(",")
        : null;
    // console.log(filter_prop_name, filter_prop_values);
    while (startIndex < total) {
      const data = {
        startIndex: startIndex,
        to: perPage,
        type: this.data.type,
        is_sfse: this.data.is_sfse,
        thematic_id: this.data.thematic_id,
        is_past: this.data.is_past,
        is_approved: true,
        filter_prop_name: this.data.filter_prop_name || filter_prop_name,
        filter_prop_values: this.data.filter_prop_values || filter_prop_values,
        table_name: this.data.table,
      };

      if (this.data.type) {
        data.type = this.data.type;
        // console.log(data.type);
      }
      // for(let i = 0; i < 10; i++) {
      this.pages.push(data);
      // }
      startIndex += perPage;
    }
  }

  loadItems(page, index) {
    const storedPrevPageNumber =
      window.sessionStorage["sfse-prev-pagedetail-number"];
    if (storedPrevPageNumber) {
      this.selectedPage = storedPrevPageNumber;
      window.sessionStorage.removeItem("sfse-prev-pagedetail-number");
      page = this.pages[this.selectedPage];
    } else {
      this.selectedPage = index;
    }

    window.sessionStorage.setItem("sfse-prev-page-number", this.selectedPage);

    page.thematic_id = this.data.thematic_id;
    const url = apiURL(this.table_name, this.data.method, "");

    ajax(
      url,
      "POST",
      page,
      (res: any) => {
        const response = JSON.parse(res);
        // if (this.data.table === "event") {
        //   console.log(this.data, response);
        // }
        const data = {
          records: response.records || [],
          index: this.data.index,
        };
        // window.scroll(0,0);
        this.output.emit(data);
      },
      false,
      (err) => {
        console.log(err);
      }
    );
  }

  countItems() {
    if (this.data.thematic_id == "") {
      this.output.emit({ records: [], index: this.data.index });
      return;
    }
    this.data.table_name = this.data.table;

    let method = "count";
    if (this.table_name == "user" && this.data.method == "read_adherent") {
      method = "count_adherents";
    }
    const filter_prop_name = window.sessionStorage.SFSE_filter_prop_name;
    const filter_prop_values = window.sessionStorage.SFSE_filter_prop_values;

    if (filter_prop_name && filter_prop_values && this.data.active_filters) {
      this.data.filter_prop_name = filter_prop_name;
      this.data.filter_prop_values = JSON.parse(filter_prop_values)
        .map((item) => item.id)
        .join(",");
    }
    // console.log(this.data);
    const url = apiURL(this.table_name, method, "");
    ajax(
      url,
      "POST",
      this.data,
      (res: any) => {
        const total = parseInt(JSON.parse(res).records);

        this.paginate(total);

        if (total > 0) {
          this.loadItems(this.pages[0], 0);
        } else {
          this.output.emit({ records: [], index: this.data.index });
        }
      },
      false
    );
  }

  ngOnInit() {
    this.countItems();
    this.eventsSubscription = this.events.subscribe(() => this.countItems());
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }
}
