import { NavItem } from "../models/NavItem";
import {
  ADHESION_PHYSIC_NAV_TEXT,
  ERS_URL,
  NAV_ITEM_TITLE_NOUS_REJOINDRE,
} from "./global";

export const NAV_URLS = {
  publication_sfse: "article/nosactions/publication_sfse/0/lire/2/4",
  contribution_sfse: "article/nosactions/contribution_sfse/0/lire/2/5",
  ri: "article/nosactions/ri/0/lire/2/6",
  event: {
    event: "article/ressources/event/event/lire-details/0/0/4/0",
    comm_appeal: "article/ressources/event/event/lire-details/0/0/4/0",
    val_act: "article/ressources/event/val_act/lire-details/0/0/4/1",
  },
  project_appeal: "article/ressources/project_appeal/0/lire-details/0/0/4/2",
  formation: "article/ressources/formation/0/lire-details/0/0/4/3",
  useful_doc: "article/ressources/useful_doc/0/lire-details/0/0/4/4",
  web_tool: "article/ressources/web_tool/0/lire-details/0/0/4/5",
  public_politic: "article/ressources/public_politic/0/lire-details/0/0/4/6",
  job_announce: "article/ressources/job_announce/0/lire-details/0/0/4/7",
  infos_divers: "article/ressources/infos_divers/0/lire-details/0/0/4/8",
};

export const NavData = {
  data: [
    {
      title: "Accueil",
      url: "/accueil",
      sideNav: [{ title: "Accueil", url: "/accueil" }],
    },
    {
      title: "Présentation",
      url: "presentation/quisommesnous",
      color: "regular",
      sideNav: [
        new NavItem(0, "presentation/quisommesnous", "Qui sommes-nous ?"),
        new NavItem(1, "presentation/organisation", "Notre organisation"),
        new NavItem(2, "presentation/bilans", "Bilans annuels"),
        new NavItem(3, "presentation/assemblees", "Assemblées générales"),
        new NavItem(4, "presentation/partenaires", "Nos partenaires"),
        new NavItem(5, "presentation/adherents", "Adhérents personnes morales"),
        new NavItem(6, "presentation/contact", "Nous contacter"),
        new NavItem(7, "presentation/soutenir", "Nous soutenir"),
      ],
    },
    {
      title: "Nos actions",
      url: "actions/main",
      color: "pink",
      sideNav: [
        new NavItem(
          8,
          "actions/revueEnvironement",
          "Revue ERS",
          "revue_ers" /*,  'Revue Environnement, Risques et Santé'*/
        ),
        new NavItem(9, "actions/annuaire", "Annuaire", "annuaire"),
        new NavItem(
          10,
          "actions/sectionReflexion",
          "Sections de réflexion / de travail",
          "section_reflexion"
        ),
        new NavItem(
          11,
          "articles/ressources/event/event/lire-details/1/0/2/3",
          "Événements scientifiques",
          "scientific_event"
        ),
        new NavItem(
          12,
          "articles/nosactions/publication_sfse/0/lire/2/4",
          "Publications de la SFSE",
          "publication_sfse"
        ),
        new NavItem(
          13,
          "articles/nosactions/contribution_sfse/0/lire/2/5",
          "Contributions de la SFSE",
          "contribution_sfse"
        ),
        new NavItem(
          14,
          "articles/nosactions/ri/0/lire/2/6",
          "Relations internationales",
          "ri"
        ),
      ],
    },
    {
      title: "Nos Congrès",
      url: "congres/presentation/null",
      color: "regular",
      sideNav: [
        new NavItem(
          15,
          "congres/presentation/null",
          "Présentation & programme"
        ),
        new NavItem(
          16,
          "congres/organisation/null",
          "Comité scientifique et d'organisation"
        ),
        new NavItem(
          17,
          "congres/appelacommunication/null",
          "Appel à communications"
        ),
        new NavItem(
          18,
          "congres/reservations/null",
          "Réservations de stands",
          null,
          "Stands pendant le Congrès"
        ),
        new NavItem(19, "congres/prix/null", "Prix décernés"),
        new NavItem(20, "congres/partenaires/null", "Partenaires"),
        new NavItem(21, "congres/fondsdocumentaire/null", "Fonds documentaire"),
        new NavItem(22, "congres/infos/null", "Informations utiles"),
        new NavItem(23, "congres/list", "Congrès passés"),
      ],
      sideNav2: [
        new NavItem(24, "congres/presentation/null", "Description"),
        new NavItem(
          25,
          "congres/presentations/null",
          "Supports de présentation"
        ),
        new NavItem(26, "congres/interviews/null", "Interviews"),
        new NavItem(27, "congres/actes/null", "Actes"),
        new NavItem(28, "congres/prixpasses/null", "Prix décernés"),
        new NavItem(29, "congres/partenaires/null", "Partenaires"),
        new NavItem(30, "congres/fondsdocumentaire/null", "Fonds documentaire"),
        new NavItem(31, "congres/list", "Retour à la liste de congrès"),
      ],
    },
    {
      title: "Ressources",
      url: "rd/main",
      color: "blue",
      sideNav: [
        new NavItem(
          32,
          "articles/ressources/event/event/lire-details/0/0/4/0",
          "Agenda",
          "agenda",
          "Agenda des manifestations en santé-environnement"
        ),
        new NavItem(
          33,
          "articles/ressources/event/val_act/lire-details/0/0/4/1",
          "Actes de valorisation des événements",
          "acts"
        ),
        new NavItem(
          34,
          "articles/ressources/project_appeal/0/lire-details/0/0/4/2",
          "Appels à projets et contributions",
          "project_appeal"
        ),
        new NavItem(
          35,
          "articles/ressources/formation/0/lire-details/0/0/4/3",
          "Formations",
          "formation"
        ),
        new NavItem(
          36,
          "articles/ressources/useful_doc/0/lire-details/0/0/4/4",
          "Documentation",
          "useful_doc",
          "Documentation"
        ),
        new NavItem(
          37,
          "articles/ressources/web_tool/0/lire-details/0/0/4/5",
          "Outils web",
          "web_tool"
        ),
        new NavItem(
          38,
          "articles/ressources/public_politic/0/lire-details/0/0/4/6",
          "Politiques publiques",
          "public_politic"
        ),
        new NavItem(
          39,
          "articles/ressources/job_announce/0/lire-details/0/0/4/7",
          "Annonces emplois",
          "job_announce"
        ),
        new NavItem(
          40,
          "articles/ressources/infos_divers/0/lire-details/0/0/4/8",
          "Informations diverses",
          "infos_divers"
        ),
      ],
    },

    {
      title: NAV_ITEM_TITLE_NOUS_REJOINDRE,
      loggedInTitle: "Mon compte",
      url: "espace_perso/accueil",
      isMembership: true,
      color: "regular",
      sideNav: [
        new NavItem(
          41,
          "espace_perso/adhesion_personne_physique",
          ADHESION_PHYSIC_NAV_TEXT,
          null,
          null
        ),
        new NavItem(
          42,
          "espace_perso/adhesion_personne_morale",
          "Adhérer : Personnes morales",
          null,
          null
        ),
        new NavItem(
          43,
          "espace_perso/inscription_congres",
          "S'inscrire au congrès SFSE",
          null,
          null,
          "user"
        ),
        new NavItem(
          44,
          "espace_perso/resume_congres",
          "Soumettre un résumé pour le congrès à venir",
          null,
          null,
          "user"
        ),
        new NavItem(
          45,
          ERS_URL,
          "Lire la revue ERS",
          null,
          "Lire la revue Environnement, Risques et Santé",
          "adherent"
        ),
        new NavItem(
          46,
          "espace_perso/annuaire",
          "Annuaire SFSE",
          null,
          "Annuaire des adhérents « personne physique »",
          "adherent"
        ),
        new NavItem(
          47,
          "espace_perso/mes_sections_de_reflexion",
          "Mes sections de reflexion",
          null,
          "Mes sections de reflexion",
          "adherent"
        ),
        new NavItem(
          48,
          "espace_perso/profil",
          "Recevoir la lettre d'information SFSE",
          null,
          null,
          "user"
        ),
        new NavItem(
          49,
          "espace_perso/nous_aider",
          "Faire un don pour soutenir les activités SFSE"
        ),
        new NavItem(
          50,
          "espace_perso/profil",
          "Mon profil",
          null,
          "Mon profil",
          "user"
        ),
      ],
    },
  ],

  getAsideNavItems(index) {
    return { ...this.data[index] };
  },

  getNavItems() {
    return this.data;
  },
};
