import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlSerializer } from '@angular/router';
import { apiURL, ajax, timestampToDate } from '../../../../providers/helpers';
import { faPlus, faSearch, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { SocialShareService } from '../../../../providers/social-share.service'

@Component({
  selector: 'app-post-detail',
  templateUrl: './post-detail.component.html',
  styleUrls: ['./post-detail.component.css']
})
export class PostDetailComponent implements OnInit {

  constructor(
    private router: ActivatedRoute, 
    private router_:Router, 
    private socialShareService: SocialShareService
  ) {}

  faSearch = faSearch;
  faPlus = faPlus;
  faArrowLeft = faArrowLeft;

  post = <any>{};
  pdf_id = null;
  navData;

  selectedIndex;
  selectedItemIndex;

  goBack() {
    const storedPrevPageNumber = window.sessionStorage["sfse-prev-page-number"];
    if(storedPrevPageNumber) {
      window.sessionStorage.setItem("sfse-prev-pagedetail-number", storedPrevPageNumber);
    }
    window.scroll(0, 0);
    this.router_.navigate([`articles/ressources/${this.navData.tableName}/${this.navData.type}/${this.navData.method}/${this.navData.is_sfse}/${this.navData.is_past}/${this.selectedIndex}/${this.selectedItemIndex}`]);
  }

  fbShare_() {
    this.socialShareService.fbShare();
  }
  
  linkedinShare_() {
    this.socialShareService.linkedinShare();
  }

  twitterShare_(desc_short) {
    this.socialShareService.twitteShare(desc_short);
  }

  setGoals(id) {
    const url = apiURL(`web_tool_goal`, 'lire', id);
    ajax(url, 'GET', null, (res: any) => {
      const goals = [...JSON.parse(res).records];
      this.post.goals = goals.map(item => {
       return item;
      });
      
    }, false, err => { ('Erreur dans le chargement des objectifs de l\'outil !'); });
  }

  setThematics(tableName, id) {
    const url = apiURL(`${tableName}_thematic`, 'lire', id);
    ajax(url, 'GET', null, (res: any) => {
      const thematics = [...JSON.parse(res).records];
      this.post.thematics = thematics.map(thematic => thematic.name);
    }, false, err => { console.log(err); });
  }

  ngOnInit() {
    window.scroll(0, 0);
    this.router.params.subscribe(data => {
      this.selectedIndex = data.selectedIndex;
      this.selectedItemIndex = data.selectedItemIndex;
      this.navData = data;
      const url = apiURL(data.tableName, 'read_one', data.postId);
      ajax(url, 'GET', null, res => {
        const response = JSON.parse(res);
        if (!response.records) { return; }
        this.post = response.records;
        if (this.post.img_data) {
          this.post.img_data = 'data:image/jpg;base64,' + this.post.img_data;
        }
        /*
        if (this.post.limit_date) {
          this.post.limit_date = timestampToDate(this.post.limit_date);
        }
        */

        /*
        this.socialShareService.updateMetas(this.post.title, this.post.description_short).then(metaUpdatedMsg => {
          (metaUpdatedMsg);
        });
        */
       if(data.tableName === 'web_tool') {
          this.setGoals(data.postId);
        }  
      
        this.setThematics(data.tableName,  data.postId);
             
      }, false);
    });
  }

}
