import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-work-sections',
  templateUrl: './work-sections.component.html',
  styleUrls: ['./work-sections.component.css']
})
export class WorkSectionsComponent implements OnInit {

  constructor() { }
  
  thinkSections = [];

  table = { name: 'think_section', method: 'lire'};


  eventsSubject: Subject<void> = new Subject<void>();

  loadItems(evt) {
    this.thinkSections = [];
    evt.records.forEach(item => {
      if (item.img_data) {
        item.img_data = "url('data:image/jpg;base64," + item.img_data + "')";
      }

      this.thinkSections.push(item);
    });
  }

  ngOnInit() {
    window.scroll(0,0); 
  }

}
