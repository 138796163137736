import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import {
  USER_ROLE_ADHERENT_ID,
  USER_STATUS_ACTIVE_ID,
} from "src/app/providers/global";
import User from "../../../models/User";
import {
  ENV,
  GLOBALS,
  ajax,
  apiURL,
  convertToBase64,
  randomChars,
} from "../../../providers/helpers";
import { PdfGeneratorService } from "../../../providers/pdf-generator.service";
import { PurchaseService } from "../../../providers/purchase.service";
import { getStoredUser } from "../../../providers/user-service";

@Component({
  selector: "app-popup-signup-congress",
  templateUrl: "./popup-signup.component.html",
  styleUrls: ["./popup-signup.component.css"],
})
export class PopupSignupComponent implements OnInit {
  @Output() close = new EventEmitter<{}>();

  @ViewChild("popupCtnr", { static: false }) popupCtnr;

  env = ENV;
  // loading = false;
  showPopup;
  popupText: string;

  signupForm;
  page = 1;

  user: User;
  congress_booking = <any>{ paiement_mode_id: "-1" };
  congress: any;
  price: number;
  pricing_tag: any;
  pricing_name: string;
  priceNoDiscount = "";
  paiement_modes = [];
  pdf_datas = "";
  pdf_name = "";
  pdf_names = "";
  cheatCode = "";

  moneticoHref = "";

  constructor(
    private router: Router,
    private purchaseService: PurchaseService,
    private pdfGeneratorService: PdfGeneratorService
  ) {}

  emitPhone($event) {
    this.congress_booking.billing_phone = $event;
  }

  sendAdhesionMail() {
    const subject = "Adhésion à la SFSE";
    // let addComptaInCopy = this.env == "prod" ? true : null;
    let addComptaInCopy = null;
    let addresses = this.user.mail;

    const data = {
      body: "adhesion_physique_congress",
      subject,
      addresses,
      // pdfDatas: this.pdf_datas,
      // pdfNames: this.pdf_names,
      // payment_mode: this.congress_booking.paiement_mode_id,
      addComptaInCopy,
      isSentByCompta: true,
    };
    const url = apiURL("-mailer_service", "phpmailer", "");
    ajax(
      url,
      "POST",
      data,
      (res: any) => {
        // this.loading = false;
        // console.log(res);
      },
      false
    );
  }

  sendCongressBookingMail() {
    const subject =
      "Confirmation de votre inscription au prochain Congrès SFSE";
    let addComptaInCopy = this.env == "prod" ? true : null;

    let addresses = this.user.mail;

    if (
      this.congress_booking.billing_mail &&
      this.user.mail !== this.congress_booking.billing_mail
    ) {
      addresses += "," + this.congress_booking.billing_mail;
    }
    const data = {
      body:
        this.congress_booking.paiement_mode_id == 3
          ? "inscription_congres_virement_simple"
          : "inscription_congres",
      subject,
      addresses,
      pdfDatas: this.pdf_datas,
      pdfNames: this.pdf_names,
      payment_mode: this.congress_booking.paiement_mode_id,
      addComptaInCopy,
      isSentByCompta: true,
    };
    const url = apiURL("-mailer_service", "phpmailer", "");
    ajax(
      url,
      "POST",
      data,
      (res: any) => {
        // this.loading = false;
        // console.log(res);
      },
      false
    );
  }

  generatePdfFactureCongress() {
    const infos: any = {
      address:
        this.congress_booking.billing_address || "adresse non communiquée",
      billNumber: this.congress_booking.billing_number,
      congressDebut: this.congress.debut,
      congressEnd: this.congress.end,
      pricing: this.pricing_name,
      resaNbr: 1,
      pu: this.price,
      userName: this.user.firstname + " " + this.user.name,
    };

    if (this.congress_booking.paiement_mode_id == 2) {
      infos.commandNumber = this.congress_booking.purchase_number;
    }
    return this.pdfGeneratorService.generatePdf(
      "facture-congres",
      infos,
      "send"
    );
  }

  generatePdfFactureCongresAcquitee() {
    const infos: any = {
      address:
        this.congress_booking.billing_address || "adresse non communiquée",
      billNumber: this.congress_booking.billing_number,
      congressDebut: this.congress.debut,
      congressEnd: this.congress.end,
      pricing: this.pricing_name,
      resaNbr: 1,
      pu: this.price,
      userName: this.user.firstname + " " + this.user.name,
      isFree: this.congress_booking.is_free,
    };

    if (this.congress_booking.paiement_mode_id == 2) {
      infos.commandNumber = this.congress_booking.purchase_number;
    }

    // console.log(infos);
    return this.pdfGeneratorService.generatePdf(
      "facture-congres-acquittee",
      infos,
      "send"
    );
  }

  endBookingMsg;
  async createBooking() {
    this.endBookingMsg = await this.createBooking_();
    this.page++;
  }

  createBooking_() {
    /* TEST PAIEMENT */
    if (this.cheatCode === "cgratuit") this.price = 1;

    let booking_is_free_msg =
        "<p>Vous êtes bien inscrit.e au congrès annuel de la SFSE ! Vous recevrez d’ici quelques instants un premier e-mail de confirmation. D’autres e-mails vous seront envoyés par la suite pour finaliser les détails techniques liés à l’événement lui-même.</p>",
      booking_with_redirect_msg =
        "<p>Vous allez être redirigé.e vers le terminal de paiement « Monetico » pour finaliser votre inscription au congrès. Une fois le paiement effectué vous recevrez un premier e-mail de confirmation accompagné de votre facture acquittée. D’autres e-mails vous seront envoyés par la suite pour finaliser les détails techniques liés à l’événement lui-même.</p>",
      booking_done_msg =
        "<p>Vous êtes bien inscrit.e au congrès annuel de la SFSE ! Vous recevrez d’ici quelques instants un premier e-mail de confirmation accompagné de votre facture à régler par virement bancaire. D’autres e-mails vous seront envoyés par la suite pour finaliser les détails techniques liés à l’événement lui-même.</p>";
    const additional_adhesion_msg =
      "<p>Pour information :  la validation de votre présente inscription au tarif « Non-adhérent » activera une adhésion d’un an à la SFSE dans votre espace personnel. Pour accéder aux avantages liés à cette adhésion, il faudra alors vous déconnecter puis vos reconnecter à votre compte.</p>";

    return new Promise((resolve, reject) => {
      // this.loading = true;
      const tStamp = new Date().getTime();
      this.congress_booking.billing_number = `F-C-${new Date().getFullYear()}-${this.user.name.toUpperCase()}-${tStamp}`;
      this.congress_booking.user_id = this.user.id;

      const url = apiURL("congress_booking", "create", "");

      const congress_booking = {
        ...this.congress_booking,
        congress_id: this.congress.id,
      };

      // si le paiement s'effectue par bon de commande/virement
      // ou par virement simple,
      // la facture sera réglée d'emblée
      if (
        this.congress_booking.paiement_mode_id == 2 ||
        this.congress_booking.paiement_mode_id == 3
      ) {
        congress_booking.billing_settled = true;
        congress_booking.amount_bdc = this.price;
      }

      ajax(
        url,
        "POST",
        congress_booking,
        (res: any) => {
          // console.log(res);
          const congressBookingInfos = JSON.parse(res).records;
          // console.log(congressBookingInfos);
          // si le paiement s'effectue par bon de commande/virement,
          if (this.congress_booking.is_free) {
            // on génère un pdf de facture acquitée
            this.generatePdfFactureCongresAcquitee().getDataUrl((dataUrl) => {
              if (this.pdf_datas.length > 0) {
                this.pdf_names += ",facture_congres_sfse";
                this.pdf_datas += `__separator__${dataUrl}`;
              } else {
                this.pdf_names = "facture_congres_sfse";
                this.pdf_datas = `${dataUrl}`;
              }
              this.sendCongressBookingMail();
              resolve(booking_is_free_msg);
            });
            // si le paiement s'effectue par monetico (this.congress_booking.paiement_mode_id == 1) et qu'aucun code promo n'est appliqué
          } else if (
            this.congress_booking.paiement_mode_id == 2 ||
            this.congress_booking.paiement_mode_id == 3
          ) {
            this.generatePdfFactureCongress().getDataUrl((dataUrl) => {
              if (this.pdf_datas.length > 0) {
                this.pdf_names += ",facture_congres_sfse";
                this.pdf_datas += `__separator__${dataUrl}`;
              } else {
                this.pdf_names += "facture_congres_sfse";
                this.pdf_datas = `${dataUrl}`;
              }

              this.sendCongressBookingMail();
              // this.loading =false;
              if (
                this.user.role_id != String(USER_ROLE_ADHERENT_ID) &&
                this.user.status_id == String(USER_STATUS_ACTIVE_ID)
              ) {
                booking_done_msg += additional_adhesion_msg;
                this.sendAdhesionMail();
              }
              resolve(booking_done_msg);
            });
          } else {
            const reference = randomChars(11);
            const urlTempPaiement = apiURL("temp_paiement", "create", "");
            const tempPaiement: any = {
              user_address:
                this.congress_booking.billing_address ||
                "adresse non communiquée",
              billing_number: this.congress_booking.billing_number,
              congress_debut: this.congress.debut,
              congress_end: this.congress.end,
              pricing_name: this.pricing_name,
              resa_nbr: 1,
              pu: this.price,
              user_fullname: this.user.firstname + " " + this.user.name,
              ref: reference,
              type: "congress_booking",
              user_id: this.user.id,
              congress_booking_id: congressBookingInfos.id,
              amount: this.price,
            };
            if (this.congress_booking.paiement_mode_id == 2) {
              tempPaiement.purchase_number =
                this.congress_booking.purchase_number;
            }
            ajax(
              urlTempPaiement,
              "POST",
              tempPaiement,
              () => {
                // console.log(res);
                this.moneticoHref = `${GLOBALS[ENV].API_URL}/--monetico/src/SendCongressBooking.php?reference=${reference}&amount=${this.price}&user_mail=${this.user.mail}&user_civility=${this.user.civility_id}&user_firstname=${this.user.firstname}&user_name=${this.user.name}&user_address=${tempPaiement.user_address}&user_phone=${this.user.phone}&postal_code=${this.user.postal_code}&user_id=${this.user.id}&billing_number=${this.congress_booking.billing_number}&congress_booking_id=${congressBookingInfos.id}`;

                window.open(this.moneticoHref, "_blank");
                if (
                  this.user.role_id != String(USER_ROLE_ADHERENT_ID) &&
                  this.user.status_id == String(USER_STATUS_ACTIVE_ID)
                ) {
                  booking_with_redirect_msg += additional_adhesion_msg;
                  this.sendAdhesionMail();
                }
                resolve(booking_with_redirect_msg);
              },
              false
            );
          }
        },
        false
      );
    });
  }

  goToProfile() {
    this.router.navigate(["/espace_perso/profil"]);
  }

  closePopup() {
    this.close.emit();
    window.scroll(0, 0);
    window.location.reload();
  }

  goToNextPage(page) {
    if (page === 2) {
      if (!this.user.cellphone || !this.user.organization_name) {
        this.popupText = `Merci de compléter votre profil avec la/les information(s) manquante(s).`;
        this.showPopup = true;
        return;
      }

      if (!this.user.status_name) {
        this.popupText = `Certaines informations liées à votre profil ne sont pas affichées, vous pouvez vous connecter de nouveau et revenir sur ce formulaire.`;
        this.showPopup = true;
        return;
      }

      if (this.paiement_modes.length === 0) {
        this.loadPaimentModes();
      }

      this.displayPricing();
    } else if (page === 3) {
      if (!this.congress_booking.is_free) {
        if (
          !this.congress_booking.paiement_mode_id ||
          this.congress_booking.paiement_mode_id == "-1"
        ) {
          this.popupText = `Vous devez renseigner un moyen de paiement.`;
          this.showPopup = true;
          return;
        }
        if (this.congress_booking.paiement_mode_id === "2") {
          if (
            !this.congress_booking.billing_fullname ||
            !this.congress_booking.billing_address ||
            !this.congress_booking.billing_mail ||
            !this.pdf_name ||
            !this.congress_booking.purchase_number
          ) {
            this.popupText = `Vous devez renseigner tous les champs obligatoires (*)`;
            this.showPopup = true;
            return;
          }
        }
        if (this.congress_booking.paiement_mode_id === "3") {
          if (
            !this.congress_booking.billing_fullname ||
            !this.congress_booking.billing_address ||
            !this.congress_booking.billing_mail
          ) {
            this.popupText = `Vous devez renseigner tous les champs obligatoires (*)`;
            this.showPopup = true;
            return;
          }
        }
      }
    } else if (page === 4) {
      if (
        this.congress_booking.is_fc != 0 &&
        this.congress_booking.is_fc != 1
      ) {
        this.popupText = `Vous devez préciser si votre inscription se fait dans le cadre d'une formation continue`;
        this.showPopup = true;
        return;
      } else if (
        this.congress_booking.is_carbon != 0 &&
        this.congress_booking.is_carbon != 1
      ) {
        this.popupText = `Venez en avion ou en voiture ? Si oui, la SFSE reversera une compensation à une association oeuvrant pour la préservation de l’environnement`;
        this.showPopup = true;
        return;
      } else if (
        this.congress_booking.is_paper != 0 &&
        this.congress_booking.is_paper != 1
      ) {
        this.popupText = `Vous devez choisir entre version papier et version électronique`;
        this.showPopup = true;
        return;
      } else if (
        this.congress_booking.mail_is_public != 0 &&
        this.congress_booking.mail_is_public != 1
      ) {
        this.popupText = `Vous devez choisir si vous souhaitez ou non que votre adresse mail soit communiquée lors de l'événement`;
        this.showPopup = true;
        return;
      }
    }

    this.page = page;
  }

  displayPricing() {
    if (this.user.status_id != String(USER_STATUS_ACTIVE_ID)) {
      this.price = this.congress.pricing_student;
      this.pricing_tag = `${this.price}€`;
      this.pricing_name = "réduit";
    } else {
      const formatedLimitDate = this.congress.pricing_limit_date
        .split("-")
        .reverse()
        .join("-");
      if (new Date(this.congress.pricing_limit_date) > new Date()) {
        if (this.user.role_id === String(USER_ROLE_ADHERENT_ID)) {
          this.priceNoDiscount = this.congress.pricing_adherent_after;
          this.price = this.congress.pricing_adherent_before;
          this.pricing_name = "adhérent avant augmentation des prix";
        } else {
          this.priceNoDiscount = this.congress.pricing_user_after;
          this.price = this.congress.pricing_user_before;
          this.pricing_name = "non-adhérent avant augmentation des prix";
        }
        this.pricing_tag = `${this.price}€ (jusqu'au ${formatedLimitDate})`;
      } else {
        if (this.user.role_id === String(USER_ROLE_ADHERENT_ID)) {
          this.price = this.congress.pricing_adherent_after;
          this.pricing_name = "adhérent";
        } else {
          this.price = this.congress.pricing_user_after;
          this.pricing_name = "non-adhérent";
        }
        this.pricing_tag = `${this.price}€`;
      }
    }
  }

  checkPromoCodeValidity() {
    this.purchaseService
      .checkPromoCode(this.congress_booking.promo_code, "congress_inscription")
      .then((res: any) => {
        if (res.result === "success") {
          this.congress_booking.is_free = true;
          this.popupText =
            "Code promotion valide. Vous pouvez effectuer votre inscription gratuitement !";
          this.congress_booking.paiement_mode_id = null;
        } else {
          this.popupText = res.msg;
          this.congress_booking.is_free = false;
        }
        this.showPopup = true;
      });
  }

  loadPaimentModes() {
    const url = apiURL("paiement_mode", "read", "");
    ajax(
      url,
      "GET",
      null,
      (res: any) => {
        this.paiement_modes = JSON.parse(res).records;
        // paiement_modes.splice(2,1);
        // this.paiement_modes = paiement_modes;
      },
      false
    );
  }

  async pdfHandler(e) {
    this.pdf_names = "";
    const pdfFile = e.target.files[0];
    this.pdf_name = pdfFile.name;
    this.pdf_datas = await convertToBase64(pdfFile);
    this.pdf_names = "bon_de_commande";
  }

  ngOnDestroy() {
    document.body.style.overflow = "auto";
  }

  ngOnInit() {
    document.body.style.overflow = "hidden";
    this.pdf_datas = "";
    this.pdf_names = "";
    this.user = getStoredUser();
    const url = apiURL("congress", "read_active", "");
    ajax(
      url,
      "GET",
      null,
      (res: any) => {
        this.congress = JSON.parse(res).records;
      },
      false
    );
  }
}
