import { Component, OnInit, OnDestroy } from '@angular/core';
import { faPlus, faSearch, faCalendar, faMapMarker, faPen } from '@fortawesome/free-solid-svg-icons';
import { GLOBALS, apiURL, ajax, truncateChars } from '../../../../providers/helpers';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router, NavigationEnd  } from '@angular/router';


@Component({
  selector: 'app-posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.css']
})
export class PostsComponent implements OnInit, OnDestroy {

  faSearch    = faSearch;
  faPen       = faPen;   
  faPlus      = faPlus;
  faCalendar  = faCalendar;
  faMapMarker = faMapMarker; 

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.navDataChanged = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if(!this.table) return;
        this.loading = true;

        this.refreshPagination()
        this.activeFilters = false;
      }
    });
  }

  items: any[] = [];
  thematics: any[] = [];

  loading     = false;
  displayAddNewForm;

  table: any;
  eventsSubject: Subject<void> = new Subject<void>();

  navDataChanged;

  is_past = false;

  filterTables  = [];
   
  displayFiltersPopup = false;

  selectedIndex;
  selectedItemIndex;
  displayAside;
  showPopup;
  popupText = '';
  noResultText = '';

  pictoName;
  activeFilters = false;
  

  refreshPagination() {
    window.sessionStorage.removeItem("SFSE_filter_prop_values");
    window.sessionStorage.removeItem("SFSE_filter_prop_name"); 
    window.sessionStorage.removeItem("SFSE_filter_page"); 

      const paginationMethod = this.table.method + '';
      this.table.method = 'false';
      setTimeout(() => {
        this.table.method = paginationMethod;
      }, 400)
  }

  cancelFilter() {
    this.activeFilters = false;
    this.refreshPagination();
  }

  loadItems(evt) {
    this.noResultText = '';
    if(evt.records.length === 0) {
      this.noResultText = 'Aucun article à afficher';
      if(this.table.filter_prop_values) {
        this.noResultText += ', changez vos critères et relancez votre recherche';
      }
    }

    this.table.filter_prop_name = null;
    this.table.filter_prop_values = null;
    this.items = [];

    if(evt.records.length === 0) {
      this.loading = false;
      return;
    }
    
    this.sortItemsWithMonthImageAndThematics(evt.records);
  }

  setThematics(id, index) {
    const url = apiURL(`${this.table.name}_thematic`, 'lire', id);
    ajax(url, 'GET', null, (res: any) => {
      const thematics = [...JSON.parse(res).records];
      this.items[index].thematics = thematics.map(item => {
       return item;
      });
      this.items[index].thematicsParam = JSON.parse(res).records.map(thematic => {
        thematic.name = thematic.name.replace(/\//gi, '{%}');
        return thematic.name;
      }).join(',') || '0';
      this.loading = false;
    }, false, err => { this.loading = false; });
  }

  sortItemsWithMonthImageAndThematics(items) {
    this.items = items.map( (item, i) => {
      if (item.img_data) {
        item.img_data = 'data:image/jpg;base64,' + item.img_data;
      }

      if(item.month && (!items[i-1] || item.month !== items[i-1].month) ) {
        item.topDate = GLOBALS.MONTHS[Number(item.month)] + ' ' + item.year;
      }

      item.description_short = truncateChars(item.description_short, 220);

      this.setThematics(item.id, i);

      item.titleForURL = item.title.split(" ").join("-");
      
      return item;
    });
  }

  switchPastEvents() {
    this.loading = true;
    this.table.is_past = this.is_past ? 0 : 1;
    this.is_past = !this.is_past;
    // const method = this.table.method + '';
    // this.table.method = 'false';
    this.activeFilters = false;
    this.refreshPagination();
    // setTimeout(() => {
    //   this.table.method = method;
    // }, 500);
  }

  filterItems({prop_values, prop_name}) {
    this.items = [];
    this.table.method = 'false';
    this.table.filter_prop_name   = prop_name;
    this.table.filter_prop_values =  prop_values.map(item => item.id).join(',');
    this.table.SFSE_filter_page   = "posts";

    setTimeout(() => {
      this.activeFilters = true;
      this.table.method = 'lire-details';
    }, 400)
  }

  addNewItem() {
    if(this.table.name === 'event' && this.table.type === 'val_act') {
      this.popupText = 'Vous ne pouvez pas ajouter d\'acte de valorisation via un formulaire. Vous pouvez ajouter un événement dans la partie agenda, puis nous communiquer par mail votre acte de valorisation à l\'adresse sfse@sfse.org.'
      this.showPopup = true;
      return;
    }
    this.displayAddNewForm = true;
  }

  ngOnInit() {
    window.scroll(0,0);
    this.loading = true;
    this.activeFilters = window.sessionStorage.SFSE_filter_prop_values && window.sessionStorage.SFSE_filter_page === "posts" ? true : false;
    this.activatedRoute.params.subscribe(data => {
      this.table = { name: '', method: '', type: '', is_sfse: 0, is_past: 0, is_approved: '1', active_filters: this.activeFilters}
      this.table.name     = data.tableName;
      this.table.type     = data.type;
      this.table.method   = data.method;
      this.table.is_sfse  = data.is_sfse == '0' ? 0 : 1;
      this.table.is_past  = data.is_past == '0' ? 0 : 1;
      this.is_past        = data.is_past !== '0';
      this.pictoName      = this.table.name === 'event' ? 'agenda' : this.table.name; 

      this.filterTables = [{name: 'Thématiques', val: 'thematic'}, {name: 'Type', val: ''}];

      this.filterTables[1].val = `${this.table.name}_type`;

      if(this.table.name === 'public_politic' || this.table.name === 'project_appeal') {
        this.filterTables.push({name: 'Dimension', val: 'dimension'});
      }
      if(this.table.name === 'val_act' || this.table.name === 'formation' || this.table.name === 'event') {
        this.filterTables.push({name: 'Pays', val: 'country'});
      }

      this.selectedIndex = data.selectedIndex;
      this.selectedItemIndex = data.selectedItemIndex;
      this.displayAside = false;
      window.setTimeout( () => {
        this.displayAside = true;
      }, 10);
    });
  }

  ngOnDestroy() {
    if (this.navDataChanged) {
      this.navDataChanged.unsubscribe();
    }
  }

}
