import { Component, OnInit } from "@angular/core";

import { ActivatedRoute, Router } from "@angular/router";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { ajax, apiURL, timestampToDate } from "../../../../providers/helpers";
import { SocialShareService } from "../../../../providers/social-share.service";

@Component({
  selector: "app-post-actions-details",
  templateUrl: "./post-actions-details.component.html",
  styleUrls: ["./post-actions-details.component.css"],
})
export class PostActionsDetailsComponent implements OnInit {
  constructor(
    private router: ActivatedRoute,
    private router_: Router,
    private socialShareService: SocialShareService
  ) {}

  faArrowLeft = faArrowLeft;
  post = <any>{};
  pdf_id = null;

  selectedItemIndex;
  selectedIndex;
  navData;

  goBack() {
    const storedPrevPageNumber = window.sessionStorage["sfse-prev-page-number"];
    if (storedPrevPageNumber) {
      window.sessionStorage.setItem(
        "sfse-prev-pagedetail-number",
        storedPrevPageNumber
      );
    }
    window.scroll(0, 0);
    this.router_.navigate([
      `articles/nosactions/${this.navData.tableName}/${this.navData.type}/${this.navData.method}/${this.selectedIndex}/${this.selectedItemIndex}`,
    ]);
  }

  fbShare_() {
    this.socialShareService.fbShare();
  }

  linkedinShare_() {
    this.socialShareService.linkedinShare();
  }

  twitterShare_(desc_short) {
    this.socialShareService.twitteShare(desc_short);
  }

  setThematics(tableName, id) {
    const url = apiURL(`${tableName}_thematic`, "lire", id);
    ajax(
      url,
      "GET",
      null,
      (res: any) => {
        const thematics = [...JSON.parse(res).records];
        // console.log(thematics);
        this.post.thematics = thematics.map((thematic) => thematic.name);
      },
      false,
      (err) => {
        console.log(err);
      }
    );
  }

  ngOnInit() {
    window.scroll(0, 0);
    this.router.params.subscribe((data) => {
      this.selectedIndex = data.selectedIndex;
      this.selectedItemIndex = data.selectedItemIndex;
      this.navData = data;

      const url = apiURL(data.tableName, "read_one", data.postId);
      ajax(
        url,
        "GET",
        null,
        (res) => {
          const response = JSON.parse(res);
          if (!response.records) {
            return;
          }
          this.post = response.records;
          if (this.post.img_data) {
            this.post.img_data = "data:image/jpg;base64," + this.post.img_data;
          }
          if (this.post.limit_date) {
            this.post.limit_date = timestampToDate(this.post.limit_date);
          }

          this.setThematics(data.tableName, data.postId);
        },
        false
      );
    });
  }
}
