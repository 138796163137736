import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ajax, apiURL } from "../../../providers/helpers";

@Component({
  selector: "app-user-think-section-field",
  templateUrl: "./user-think-section-field.component.html",
  styleUrls: ["./user-think-section-field.component.css"],
})
export class UserThinkSectionFieldComponent {
  @Input() userId: any;
  @Input() doNotShowUpdateButton: any;
  @Input() displayUncheckedThinkSections: boolean;
  @Output() outputThinkSections = new EventEmitter<any[]>();

  thinkSections: any = [];
  currentUserThinkSections = [];
  userThinkSections: any = [];
  popupText = "";
  showPopup = false;
  numberOfThinksectionsModified = 0;

  constructor() {}

  incrementModifications() {
    this.numberOfThinksectionsModified++;
  }

  decrementModifications() {
    this.numberOfThinksectionsModified--;
  }

  updateUserThinkSection($event, i) {
    const belongsToUser = $event.target.value;
    if (this.currentUserThinkSections[i] == belongsToUser) {
      this.decrementModifications();
    } else {
      this.incrementModifications();
    }
    this.outputThinkSections.emit(this.thinkSections);
  }

  updateUserThinkSections() {
    const userThinkSectionsData = {
      think_section_ids: this.thinkSections
        .filter((item) => item.belongsToUser == "1")
        .map((item) => item.id)
        .join(","),
      user_id: this.userId,
    };
    if (userThinkSectionsData.think_section_ids.length === 0) {
      userThinkSectionsData.think_section_ids = ["none"];
    }
    const userThinksectionUrl = apiURL("user_think_section", "update", "");
    ajax(
      userThinksectionUrl,
      "POST",
      userThinkSectionsData,
      (res: any) => {
        this.popupText =
          "Les sections de réflexion auxquelles vous souhaitez participer ont été mises à jour";
        this.showPopup = true;
        this.initCurrentUserThinkSections();
      },
      false
    );
  }

  initCurrentUserThinkSections() {
    this.currentUserThinkSections = this.thinkSections.map(
      (thinkSection) => thinkSection.belongsToUser
    );
    this.numberOfThinksectionsModified = 0;
  }

  loadItems(table_name, method, id) {
    return new Promise((resolve) => {
      const url = apiURL(table_name, method, id);
      ajax(
        url,
        "GET",
        null,
        (res: any) => {
          resolve(JSON.parse(res).records);
        },
        false
      );
    });
  }

  async setThinkSectionsAndCurrentThinkSections() {
    this.thinkSections = await this.loadItems(
      "think_section",
      "read_without_data_not_archived",
      null
    );
    this.userThinkSections = await this.loadItems(
      "user_think_section",
      "lire",
      this.userId
    );
    const thinkSections = this.thinkSections.map((thinkSection) => {
      if (
        this.userThinkSections.findIndex(
          (userThinkSection) => userThinkSection.id == thinkSection.id
        ) > -1
      ) {
        thinkSection.belongsToUser = "1";
      } else {
        if (!this.displayUncheckedThinkSections) {
          thinkSection.belongsToUser = "none";
        } else {
          thinkSection.belongsToUser = "0";
        }
      }

      return thinkSection;
    });

    this.thinkSections = [...thinkSections];
    this.initCurrentUserThinkSections();
  }

  ngOnInit() {
    this.setThinkSectionsAndCurrentThinkSections();
  }
}
