import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { CaptchaComponent } from "angular-captcha";
import User from "../../../models/User";
import {
  CGU_PDF_NAME,
  COUNTRIES_WITH_REGIONS,
  SITE_KEY,
} from "../../../providers/global";
import {
  ENV,
  GLOBALS,
  ajax,
  apiURL,
  resizeImage,
} from "../../../providers/helpers";
import {
  getStoredUser,
  updateStoredUser,
} from "../../../providers/user-service";

@Component({
  selector: "app-login-popup",
  templateUrl: "./login-popup.component.html",
  styleUrls: ["./login-popup.component.css"],
})
export class LoginPopupComponent implements OnInit, OnChanges {
  // @Output() mouseentered  = new EventEmitter<{}>();
  // @Output() mouseleft     = new EventEmitter<{}>();
  @Output() close = new EventEmitter<{}>();
  @Input() displayed: string;

  @ViewChild("popupCtnr", { static: false }) popupCtnr;
  @ViewChild(CaptchaComponent, { static: true })
  captchaComponent: CaptchaComponent;

  SITE_KEY = SITE_KEY;
  cguPdfName = CGU_PDF_NAME;
  pdf_id;
  showPopup;
  popupText: string;
  newPdfSrc;

  COUNTRIES_WITH_REGIONS = COUNTRIES_WITH_REGIONS;
  loginForm;
  signupForm;
  adhesionForm;
  adhesionPage = 1;
  statuses = [];
  countries = [];
  organizationCategories = [];
  regions = [];
  competences = [];
  pwdIsValid;
  userOrganizationName: string;
  userOrganizationCategoryName: string;
  isValidMail = true;
  thematics = [];
  thematicsSub = [];
  selectedThematicsSub = [];
  selectedThematicId;
  user: User;

  loading;
  formIsComplete = false;
  pwdsAreTheSame = false;

  chosenImg;
  chosenImgSrc;
  resizedImg;
  resizedImgSrc;
  displaySignupBtn = false;
  displayCaptcha = true;
  mainView;
  cellphone = "";
  constructor(private formBuilder: FormBuilder, private router: Router) {
    this.user = new User(getStoredUser());

    const formValues = [
      {
        civility_id: "",
        status_id: "",
        country_id: "",
        firstname: "",
        name: "",
        mail: "",
        mailConfirm: "",
        mail_is_public: "1",
        pwd: "",
        pwdConfirm: "",
        category_is_correct: "",
        organization_subtitle: "",
        newsletter: "none",
        // accepts_cgu: false
      },
      {
        civility_id: "",
        status_id: "",
        country_id: "",
        region_id: "",
        firstname: "",
        name: "",
        mail: "",
        mailConfirm: "",
        pwd: "",
        pwdConfirm: "",
        phone: "",
        cellphone: "",
        code_promo: "",
        mail_pro: "",
        activity_domain: null,
        category_is_correct: "1",
        organization_2: "",
        full_address: "",
        competence_id: "",
        competence2_id: "",
        competence3_id: "",
        occupied_function: "",
        linkedin: "",
        ers_paper: "",
        is_visible: "",
        // accepts_cgu: false
      },
      {
        mail: "",
        pwd: "",
      },
    ];

    formValues.forEach((form, i) => {
      for (const key in form) {
        if (key === "newsletter") {
          formValues[i][key] = "none";
        } else if (this.user[key]) {
          formValues[i][key] = this.user[key];
        }
      }
    });

    this.signupForm = this.formBuilder.group(formValues[0]);
    this.adhesionForm = this.formBuilder.group(formValues[1]);
    this.loginForm = this.formBuilder.group(formValues[2]);
  }

  emitPhone($event) {
    this.cellphone = $event;
  }

  resolvedCaptcha(captchaResponse: string) {
    this.displaySignupBtn = true;
    this.displayCaptcha = false;
    // document.getElementById("newsletter-field").scrollIntoView({
    //   block: "start",
    //   behavior: "smooth",
    // });
    console.log("bravo, captcha validée !");
    window.setTimeout(() => {
      document.getElementById("submit-btn").scrollIntoView(true);
    }, 400);
  }

  handleScrollOnBottomRadioChange($event) {
    $event.target.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  }

  onCaptchaError(error) {
    this.displaySignupBtn = false;
    // console.log(`error with captcha: ${error}`);
  }

  setImgToUpload(e) {
    this.chosenImg = e.target.files[0];
    const reader: FileReader = new FileReader();
    reader.readAsDataURL(this.chosenImg);

    reader.onloadend = (e: any) => {
      this.chosenImgSrc = reader.result;

      resizeImage(e, this.chosenImg.name, this.chosenImg.type, 0.2, 50).then(
        (resizedImg: File) => {
          this.resizedImg = resizedImg;

          reader.readAsDataURL(this.resizedImg);
          reader.onloadend = () => {
            this.resizedImgSrc = reader.result;
          };
        }
      );
    };
  }

  openPdf(pdf_name) {
    const pdfURL = apiURL("pdf", "read_one_id", "");
    ajax(
      pdfURL,
      "POST",
      { name: this[pdf_name] },
      (res) => {
        // console.log(res, pdf_name);
        this.pdf_id = JSON.parse(res).records.id;
      },
      false
    );
  }

  onSubmitPDF(e) {
    e.preventDefault();

    const formData = new FormData(e.target);

    const pdfURL = apiURL("pdf", "create", "");
    ajax(
      pdfURL,
      "POST",
      formData,
      (res) => {
        const pdfURL = apiURL("pdf", "read_one", JSON.parse(res).newPdfId);
        ajax(
          pdfURL,
          "GET",
          null,
          (newPDF) => {
            newPDF = JSON.parse(newPDF);
            newPDF.originalName = newPDF.name;
            this.newPdfSrc = null;
          },
          false
        );
      },
      true
    );
  }

  resetPassword(e) {
    e.preventDefault();
    if (!this.loginForm.value.mail) {
      this.popupText =
        'Renseignez votre adresse mail dans le champs "email" ci-dessus, un message vous sera renvoyé à cette adresse pour réinitialiser votre mot de passe.';
      this.openPopupAndWindow();
    } else {
      // const url = apiURL('user', 'send_new_pwd', '');
      const url = apiURL("temp_password", "create", "");
      const mail = this.loginForm.value.mail;
      ajax(
        url,
        "POST",
        { mail },
        (res: any) => {
          // console.log(res);
          // this.popupText = JSON.parse(res).message;
          this.popupText = `Un e-mail vous a été envoyé pour réinitialiser votre mot de passe`;
          this.openPopupAndWindow();
        },
        false,
        (error: any) => {
          // console.log(error);
          this.popupText = JSON.parse(error).message;
          this.popupText = "Adresse mail non reconnue";
          this.openPopupAndWindow();
        }
      );
    }
  }

  sendConfirmSignupMail() {
    const mailObj = {
      addresses: this.signupForm.value.mail,
      body: `<h3>Félicitations ! Vous êtes désormais inscrit au site de la SFSE.</h3>
      <p>
        ${" "}Votre adresse mail : ${
        this.signupForm.value.mail
      } vous servira d'identifiant.
      </p>`,
      subject: "Bienvenue à la SFSE",
    };
    const url = apiURL("-mailer_service", "phpmailer", "");
    ajax(
      url,
      "POST",
      mailObj,
      (res: any) => {
        // console.log('mail envoyé !');
      },
      false,
      (error: any) => {
        this.popupText = JSON.parse(error).message;
        this.openPopupAndWindow();
      }
    );
  }

  login(data) {
    this.loading = true;
    const url = apiURL("user", "login", "");
    ajax(
      url,
      "POST",
      data,
      (res: any) => {
        // console.log(res);
        this.loading = false;
        const result = JSON.parse(res).records;
        // console.log(result);
        if (!result) {
          this.popupText = `Email et/ou mot de passe non reconnu(s) !`;
          this.showPopup = true;
          return;
        }

        this.user = result;

        this.popupText = `(Re)bonjour et bienvenue ${this.user.civility} ${this.user.name} !`;
        if (this.user["adhesionJustExpired"]) {
          this.popupText += ` Attention, votre adhésion n'ayant pas été renouvelée, vous n'avez plus accès aux avantages adhérent (notamment l'annuaire en ligne et l'accès à la revue ERS).`;
          this.user.role_id = "4";
          this.user.role_name = "Non-adhérent";
        }
        this.showPopup = true;

        updateStoredUser(this.user);

        this.formIsComplete = true;
      },
      false,
      (error: any) => {
        // console.log(error);
        this.loading = false;
        this.popupText = JSON.parse(error).records;
        this.openPopupAndWindow();
      }
    );
  }

  allFieldsAreCorrect() {
    const errors = [];
    if (!this.signupForm.value.civility_id) {
      errors.push("Votre civilité (M./Mme)");
    }
    if (!this.signupForm.value.status_id) {
      errors.push("Votre statut (étudiant, actif, retraité ...)");
    }
    if (!this.userOrganizationName && this.signupForm.value.status_id != 5) {
      errors.push("Votre organisme principal de rattachement");
    }
    if (!this.signupForm.value.firstname) {
      errors.push("Votre prénom");
    }
    if (!this.signupForm.value.name) {
      errors.push("Votre nom");
    }
    if (
      !this.isValidMail ||
      this.signupForm.value.mail !== this.signupForm.value.mailConfirm
    ) {
      errors.push("Votre adresse mail");
    }
    if (
      !this.pwdIsValid ||
      this.signupForm.value.pwd !== this.signupForm.value.pwdConfirm
    ) {
      errors.push("Votre mot de passe");
    }
    if (this.signupForm.value.newsletter === "none") {
      errors.push("Recevoir ou non la newsletter de la SFSE.");
    }

    // if (!this.signupForm.value.accepts_cgu) {
    //   errors.push('Accepter les CGU');
    // }

    if (errors.length > 0) {
      this.popupText =
        "<h2>Liste des champs à remplir ou à corriger</h2>" +
        errors.join("<br>");
      this.openPopupAndWindow();
    }

    return errors.length === 0;
  }

  signup() {
    if (!this.allFieldsAreCorrect()) {
      return;
    }
    this.createAccount();
  }

  selectedCountryHasChanged(event) {
    const index = this.countries.findIndex(
      (country) => country.id == event.target.value
    );
    this.user.country_name = this.countries[index].name;
  }

  initRegions() {
    const country_id = this.adhesionForm.value.country_id;
    const index = this.countries.findIndex((c) => c.id == country_id);

    if (COUNTRIES_WITH_REGIONS[country_id]) {
      const url = apiURL("region", "read", country_id);
      ajax(
        url,
        "GET",
        null,
        (res: any) => {
          this.regions = JSON.parse(res).records || [];
        },
        false
      );
    } else {
      this.regions = [];
      this.adhesionForm.value.region_id = "";
      this.user.region_name = "";
      updateStoredUser(this.user);
    }
  }

  regionWasSelected(event) {
    const index = this.regions.findIndex(
      (region) => region.id == event.target.value
    );
    this.user.region_name = this.regions[index].name;
    updateStoredUser(this.user);
  }

  loadCountries() {
    const url = apiURL("country", "lire", "");
    ajax(
      url,
      "GET",
      null,
      (res: any) => {
        this.countries = JSON.parse(res).records || [];
        if (this.user.country_id) {
          const index = this.countries.findIndex(
            (c) => c.id == this.user.country_id
          );
        }
      },
      false
    );
  }

  updateOrCreateAccount(queryMethod, data, success) {
    const url = apiURL("user", queryMethod, this.user.id);
    ajax(
      url,
      "POST",
      data,
      (res: any) => {
        this.popupText =
          queryMethod === "update"
            ? "Vos informations ont été mises à jour !"
            : "Votre compte utilisateur relié à l'adresse mail " +
              this.user.mail +
              " a bien été créé !";
        this.openPopupAndWindow();
        success(res);
      },
      false,
      (error: any) => {
        error;
        this.adhesionPage--;
        this.popupText = JSON.parse(error).message;
        this.openPopupAndWindow();
      }
    );
  }

  createOrganization() {
    return new Promise((resolve, reject) => {
      const url = apiURL("organization", "create", "");
      ajax(
        url,
        "POST",
        { name: this.userOrganizationName },
        (res: any) => {
          resolve(JSON.parse(res).records.id);
        },
        false,
        (err) => {
          reject(err);
        }
      );
    });
  }

  async createAccount() {
    this.loading = true;
    const userData = {
      ...this.signupForm.value,
      organization_id: this.user.organization_id,
      cellphone: this.cellphone,
    };
    const url = apiURL("user", "create", this.user.id);

    if (!userData.organization_id && this.userOrganizationName) {
      userData.organization_id = await this.createOrganization();
    }

    ajax(
      url,
      "POST",
      userData,
      (res: any) => {
        this.popupText =
          "Votre compte utilisateur relié à l'adresse mail " +
          this.signupForm.value.mail +
          " a bien été créé !";
        this.openPopupAndWindow();
        this.sendConfirmSignupMail();
        this.login({ pwd: userData.pwd, mail: userData.mail });
      },
      false,
      (error: any) => {
        this.loading = false;
        this.closePopupAndWindow();
        this.popupText = JSON.parse(error).message;
        this.showPopup = true;
        console.log("#########", error);
      }
    );
  }

  canGoToPage2() {
    const errors = [];
    if (!this.adhesionForm.value.civility_id) {
      errors.push("Votre civilité (M./Mme)");
    }
    if (!this.adhesionForm.value.status_id) {
      errors.push("Votre statut (étudiant, actif, retraité ...)");
    }
    if (!this.userOrganizationName) {
      errors.push("Votre organisme principal de rattachement");
    }
    if (!this.adhesionForm.value.country_id) {
      errors.push("Votre pays");
    }
    if (
      COUNTRIES_WITH_REGIONS[this.adhesionForm.value.country_id] &&
      !this.adhesionForm.value.region_id
    ) {
      errors.push("Votre région");
    }
    if (!this.adhesionForm.value.firstname) {
      errors.push("Votre prénom");
    }
    if (!this.adhesionForm.value.name) {
      errors.push("Votre nom");
    }
    if (
      !this.isValidMail ||
      this.adhesionForm.value.mail !== this.adhesionForm.value.mailConfirm
    ) {
      errors.push("Votre adresse mail");
    }
    if (
      !this.pwdIsValid ||
      this.adhesionForm.value.pwd !== this.adhesionForm.value.pwdConfirm
    ) {
      errors.push("Votre mot de passe");
    }
    /*
    if (!this.adhesionForm.value.cellphone) {
      errors.push('Votre numéro de mobile');
    }
    */
    if (errors.length > 0) {
      this.popupText =
        "<h2>Liste des champs à remplir ou à corriger</h2>" +
        errors.join("<br>");
      this.openPopupAndWindow();
    }

    return errors.length === 0;
  }

  goNextPage() {
    window.scroll(0, 0);

    if (this.adhesionPage === 1 && !this.canGoToPage2()) {
      return;
    }

    this.popupCtnr.nativeElement.scrollTo(0, 0);

    if (this.adhesionPage === 1 && !this.user.organization_id) {
      const url = apiURL("organization", "create", "");
      ajax(
        url,
        "POST",
        { name: this.userOrganizationName },
        (res: any) => {
          this.user.organization_id = JSON.parse(res).id;
          this.updateUser();
          this.adhesionPage++;
        },
        false
      );
      return;
    }

    if (this.adhesionPage === 1 && this.competences.length < 1) {
      this.loadCompetences();
    }

    if (this.adhesionPage === 1 && this.thematics.length < 1) {
      this.loadThematics();
    }

    if (this.adhesionPage === 2) {
      const userThematicsSubData = {
        thematic_sub_id: this.selectedThematicsSub
          .map((item) => item.id)
          .join(","),
        user_id: this.user.id,
      };
      const userThematicsSubUrl = apiURL("user_thematic_sub", "update", "");
      ajax(
        userThematicsSubUrl,
        "POST",
        userThematicsSubData,
        (res: any) => {},
        false
      );
    }

    this.updateUser();
    this.adhesionPage++;
  }

  updateUser() {
    this.user.implement(this.adhesionForm.value);
    const queryMethod =
      this.adhesionPage === 1 && !this.user.id ? "create" : "update";
    const userData = {
      ...this.adhesionForm.value,
      id: this.user.id,
      organization_id: this.user.organization_id,
    };

    this.updateOrCreateAccount(queryMethod, userData, (res) => {
      if (queryMethod === "create") {
        this.user.id = JSON.parse(res).records;
      }
      updateStoredUser(this.user);
    });
  }

  goPreviousPage() {
    this.adhesionPage--;
  }

  closePopup() {
    this.close.emit();
  }

  onSubmitLoginForm(data) {
    // this.loginForm.reset();
    console.warn("Connexion en cours ...", data);
    this.login(data);
  }

  onSubmitSignupForm(data) {
    // this.loginForm.reset();
    console.warn("Connexion en cours ...", data);
    this.signup();
  }

  onSubmitAdhesionForm(data) {
    // this.loginForm.reset();
    console.warn("Connexion en cours ...", data);
  }

  passwordIsValid(pwd, validPropName) {
    if (pwd.match(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/)) {
      this[validPropName] = true;
    } else {
      this[validPropName] = false;
    }
  }

  mailIsValid(mail, validPropName) {
    if (mail.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
      this[validPropName] = true;
    } else {
      this[validPropName] = false;
    }
  }

  textsAreIdenticals(text1, text2, validPropName) {
    if (text1 === text2) {
      this[validPropName] = true;
    } else {
      this[validPropName] = false;
    }
  }

  setOrganizationSuggestion(evt) {
    if (evt.id) {
      this.userOrganizationCategoryName = evt.cat_name;
      this.userOrganizationName = evt.name;
      this.user.organization_id = evt.id;
    }
  }

  loadCompetences() {
    const url = apiURL("competence", "read_without_data", "");
    ajax(
      url,
      "GET",
      null,
      (res: any) => {
        const competences = JSON.parse(res).records;
        competences.forEach((competence, i) => {
          if (
            competences[i - 1] &&
            competences[i - 1].group_number !== competence.group_number
          ) {
            this.competences.push({ name: "", disabled: true });
          }
          this.competences.push(competence);
        });
      },
      false
    );
  }

  loadThematics() {
    const url = apiURL("thematic", "lire", "");
    ajax(
      url,
      "GET",
      null,
      (res: any) => {
        this.thematics = JSON.parse(res).records || [];
      },
      false
    );
  }

  loadThematicsSub(thematic_id) {
    if (this.selectedThematicId === thematic_id) {
      this.selectedThematicId = "";
      return;
    }
    this.selectedThematicId = thematic_id;
    const url = apiURL("thematic_sub", "read_by_thematic", thematic_id);
    ajax(
      url,
      "GET",
      null,
      (res: any) => {
        this.thematicsSub = JSON.parse(res).records || [];
      },
      false
    );
  }

  selectSubThematic(thematic) {
    if (
      this.selectedThematicsSub.findIndex((item) => item.id == thematic.id) < 0
    ) {
      this.selectedThematicsSub.push(thematic);
    }
  }

  clearThematicsSub() {
    this.selectedThematicsSub = [];
  }

  thinkSectionsWereUpdated(txt) {
    this.popupText = txt;
    this.openPopupAndWindow();
  }

  validate(value, valid) {
    // get the user-entered captcha code value to be validated at the backend side
    let userEnteredCaptchaCode = this.captchaComponent.userEnteredCaptchaCode;
    // get the id of a captcha instance that the user tried to solve
    let captchaId = this.captchaComponent.captchaId;

    const postData = { userEnteredCaptchaCode, captchaId };
    const url = apiURL("-captcha", "verify", "");
    ajax(
      url,
      "POST",
      postData,
      (res: any) => {
        const response = JSON.parse(res);
        if (!response.isHuman) {
          // captcha validation failed; reload image
          this.captchaComponent.reloadImage();
          // TODO: maybe display an error message, too
          alert("erreur");
        } else {
        }
      },
      (error) => {
        throw new Error(error);
      },
      false
    );
  }

  openPopupAndWindow() {
    this.showPopup = true;
  }

  closePopupAndWindow() {
    this.showPopup = null;
    if (this.formIsComplete == true) {
      this.closePopup();
      // this.router.navigate(['espace_perso/accueil']);
      this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
        this.router.navigate(["espace_perso/accueil"]);
      });
    }
  }

  ngOnChanges() {
    if (this.captchaComponent) {
      this.captchaComponent.captchaEndpoint =
        GLOBALS[ENV].API_URL + "/botdetect-captcha-lib/botdetect.php";
    }
  }
  ngOnInit(): void {
    if (this.captchaComponent) {
      this.captchaComponent.captchaEndpoint =
        GLOBALS[ENV].API_URL + "/botdetect-captcha-lib/botdetect.php";
    }

    this.loadCountries();
    // this.loadThematics();
    const url = apiURL("user_status", "lire", "");
    ajax(
      url,
      "GET",
      null,
      (res: any) => {
        this.statuses = JSON.parse(res).records || [];
      },
      false
    );

    const url2 = apiURL("organization_category", "lire", "");
    ajax(
      url2,
      "GET",
      null,
      (res: any) => {
        this.organizationCategories = JSON.parse(res).records;
      },
      false
    );

    this.mainView = document.getElementById("popup-ctnr");
  }
}
