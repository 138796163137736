
export const API_CREDENTIALS = 'toto';
export const MONTHS = [
    '', 'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 
    'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
];
export const STORAGE_ID = 'sfse_bruno_heritier_developpeurweb';
export const SITE_KEY = '6LfJjScaAAAAAO7Z4H8XTZJznQTW88yx9SDVxzr5';

export const COUNTRIES_WITH_REGIONS = {
    '21': 'Belgique',
    '38': 'Canada',
    '73': 'France',
    // '74': 'France (Outre-mer)',
    '213': 'Suisse'
};

export const ERS_URL_KEY_PARAM = 'cle_sfse=GifudUDifsrt65fduret';
export const ERS_URL_WITH_BLANK_URI = 'https://www.jle.com/silentlogin.phtml?' + ERS_URL_KEY_PARAM + "&uri=";
export const ERS_URL_URI_ARTICLES = '/fr/revues/ers/revue.phtml';

export const ERS_URL = ERS_URL_WITH_BLANK_URI + ERS_URL_URI_ARTICLES;


export const ADHESION_PHYSIC_NAV_TEXT       = 'Adhérer : Personnes physiques';
export const NAV_ITEM_TITLE_NOUS_REJOINDRE  = 'Nous rejoindre';



// role ids
export const USER_ROLE_ADHERENT_ID      = 3;
export const USER_ROLE_NON_ADHERENT_ID  = 4;


// status ids
export const USER_STATUS_ACTIVE_ID          = 1;
export const USER_STATUS_RETIRED_ID         = 2;
export const USER_STATUS_STUDENT_ID         = 3;
export const USER_STATUS_STUDENT_DOCTOR_ID  = 4;
export const USER_STATUS_UNEMPLOYED_ID      = 5;

// adhesion personne morale
export const SCIENTIFIC_SOCIETY_PRICE           = 150;
export const ASSOCIATION_LOW_PRICE              = 250;
export const ASSOCIATION_HIGH_PRICE             = 350;
export const COLLECTIVITY_LOW_PRICE             = 350;
export const COLLECTIVITY_HIGH_PRICE            = 450;
export const INTERNATIONAL_PRICE                = 600;
export const PRIVATE_ORGANIZATION_LOW_PRICE     = 350;
export const PRIVATE_ORGANIZATION_HIGH_PRICE    = 450;

// adhesion personne physique
export const REGULAR_SIGNUP_PRICE                   = 70;
export const STUDENT_SIGNUP_PRICE                   = 50;
export const PROMO_CODE_SCIENTIFIC_SOCIETY_PRICE    = 45;

export const ERS_SUBSCRIPTION_AMOUNT    = 40;

// ids promo_code
export const FREE_PROMO_CODE                    = 1;
export const SCIENTIFIC_SOCIETY_PROMO_CODE      = 2;
export const CONGRESS_PROMO_CODE                = 3;


// noms réservés de pdfs sur le site
export const CGU_PDF_NAME                       = 'sfse_cgu';
export const REGLEMENT_INTERIEUR_PDF_NAME       = 'sfse_reglement_interieur';
export const STATUS_PDF_NAME                    = 'sfse_statuts';
export const PLAQUETTE_PRESENTATION_PDF_NAME    = 'sfse_plaquette_de_presentation';
export const AG_MODELE_PROCURATION_PDF_NAME     = 'sfse_ag_modele_de_procuration';
export const AVANTAGES_ADHESION_MORAL_PDF_NAME  = 'sfse_avantages_adhesion_morale';
export const AVANTAGES_ADHESION_PHYSIC_PDF_NAME = 'sfse_avantages_adhesion_physique';
export const PRICINGS_ADHESION_MORAL_PDF_NAME   = 'sfse_tarifs_adhesion_morale';
export const PROCEDURE_ADHESION_PHYSIC          = 'sfse_procedure_adhesion_personne_physique';
export const PROCEDURE_ADHESION_MORAL           = 'sfse_procedure_adhesion_personne_morale';


export const BANK_COORDS = {
    banque: '10278',
    guichet: '06177',
    noCompte: '00020348101',
    cle: '73',
    iban: 'FR76 1027 8061 7700 0203 4810 173',
    bic: 'CMCIFR2A'
};

// ids de dossiers images + PDF réservés dans le BO
export const PDF_FOLDER_SUBMITED_ID = '100';
export const PDF_FOLDER_USER_CV_ID  = '101';

export const IMG_FOLDER_SUBMITED_ID = '100';
export const IMG_FOLDER_USER_ID     = '3';


export const SFSE_COMPTABILITE_MAIL = 'comptabilite@sfse.org';

