import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { HomeComponent } from "./views/home/home.component";
import { PostActionsDetailsComponent } from "./views/template/posts/post-actions-details/post-actions-details.component";
import { PostDetailComponent } from "./views/template/posts/post-detail/post-detail.component";
import { PostsActionsComponent } from "./views/template/posts/posts-actions/posts-actions.component";
import { PostsComponent } from "./views/template/posts/posts/posts.component";
import { ThematicSearchComponent } from "./views/thematic-search/thematic-search.component";
import { TehmaticSearchDetailsComponent } from "./views/thematicSearch/details/tehmatic-search-details/tehmatic-search-details.component";

import { ActionsMainComponent } from "./views/actions/main/main.component";
import { MembershipMainComponent } from "./views/membership/main/main.component";
import { PresentationMainComponent } from "./views/presentation/main/main.component";
import { RdMainComponent } from "./views/rd/main/main.component";

//Présentation
import { AdherentsComponent } from "./views/presentation/adherents/adherents.component";
import { AssembleesComponent } from "./views/presentation/assemblees/assemblees.component";
import { BilansComponent } from "./views/presentation/bilans/bilans.component";
import { ContactComponent } from "./views/presentation/contact/contact.component";
import { OrganisationComponent } from "./views/presentation/organisation/organisation.component";
import { PartenairesComponent } from "./views/presentation/partenaires/partenaires.component";
import { QuisommesnousComponent } from "./views/presentation/quisommesnous/quisommesnous.component";
import { SoutenirComponent } from "./views/presentation/soutenir/soutenir.component";

//Nos actions
import { DocumentaryCenterComponent } from "./views/actions/documentary-center/documentary-center.component";
import { EnvironmentJournalComponent } from "./views/actions/environment-journal/environment-journal.component";
import { WorkSectionDetailsComponent } from "./views/actions/work-section-details/work-section-details.component";
import { WorkSectionsComponent } from "./views/actions/work-sections/work-sections.component";

//Congrès
import { AwardsComponent } from "./views/congreses/awards/awards.component";
import { CommunicationComponent } from "./views/congreses/communication/communication.component";
import { DocFoundComponent } from "./views/congreses/doc-found/doc-found.component";
import { InfosComponent } from "./views/congreses/infos/infos.component";
import { OrganizationComponent } from "./views/congreses/organization/organization.component";
import { PartnersComponent } from "./views/congreses/partners/partners.component";
import { PresentationComponent } from "./views/congreses/presentation/presentation.component";
import { ReservationComponent } from "./views/congreses/reservation/reservation.component";

import { CongresesListComponent } from "./views/congreses/congreses-list/congreses-list.component";

import { ActsComponent } from "./views/congreses/acts/acts.component";
import { AwardsListComponent } from "./views/congreses/awards-list/awards-list.component";
import { InterviewsComponent } from "./views/congreses/interviews/interviews.component";
import { PresentationsListComponent } from "./views/congreses/presentations-list/presentations-list.component";

import { FilterWithThematicComponent } from "./views/rd/filter-with-thematic/filter-with-thematic.component";

import { AdhesionMoraleComponent } from "./views/membership/adhesion-morale/adhesion-morale.component";
import { AdhesionPhysiqueComponent } from "./views/membership/adhesion-physique/adhesion-physique.component";
import { AnnuaireComponent } from "./views/membership/annuaire/annuaire.component";
import { CongressResumeComponent } from "./views/membership/congress-resume/congress-resume.component";
import { CongressSignupComponent } from "./views/membership/congress-signup/congress-signup.component";
import { DonnationComponent } from "./views/membership/donnation/donnation.component";
import { MyThinkSectionDocsComponent } from "./views/membership/my-think-sections/my-think-section-docs/my-think-section-docs.component";
import { MyThinkSectionsListComponent } from "./views/membership/my-think-sections/my-think-sections-list/my-think-sections-list.component";

import { ProfilComponent } from "./views/membership/profil/profil.component";

import { TransitionPageComponent } from "./views/transition-page/transition-page.component";

const routes: Routes = [
  { path: "transition_page", component: TransitionPageComponent },
  { path: "accueil", component: HomeComponent },

  { path: "rechercher_par_thematique", component: ThematicSearchComponent },
  {
    path: "rechercher_par_thematique/:thematic_name/:thematicId",
    component: TehmaticSearchDetailsComponent,
  },

  { path: "presentation/main", component: PresentationMainComponent },
  { path: "presentation/quisommesnous", component: QuisommesnousComponent },
  { path: "presentation/organisation", component: OrganisationComponent },
  { path: "presentation/bilans", component: BilansComponent },
  { path: "presentation/assemblees", component: AssembleesComponent },
  { path: "presentation/partenaires", component: PartenairesComponent },
  { path: "presentation/adherents", component: AdherentsComponent },
  { path: "presentation/contact", component: ContactComponent },
  { path: "presentation/soutenir", component: SoutenirComponent },

  { path: "actions/main", component: ActionsMainComponent },
  { path: "actions/revueEnvironement", component: EnvironmentJournalComponent },
  { path: "actions/annuaire", component: DocumentaryCenterComponent },
  { path: "actions/sectionReflexion", component: WorkSectionsComponent },
  {
    path: "actions/sectionReflexionDetails/:sectionId",
    component: WorkSectionDetailsComponent,
  },

  { path: "congres/presentation/:id", component: PresentationComponent },
  { path: "congres/organisation/:id", component: OrganizationComponent },
  {
    path: "congres/appelacommunication/:id",
    component: CommunicationComponent,
  },
  { path: "congres/reservations/:id", component: ReservationComponent },
  { path: "congres/prix/:id", component: AwardsComponent },
  { path: "congres/partenaires/:id", component: PartnersComponent },
  { path: "congres/fondsdocumentaire/:id", component: DocFoundComponent },
  { path: "congres/infos/:id", component: InfosComponent },

  { path: "congres/list", component: CongresesListComponent },

  { path: "congres/presentations/:id", component: PresentationsListComponent },
  { path: "congres/prixpasses/:id", component: AwardsListComponent },
  { path: "congres/interviews/:id", component: InterviewsComponent },
  { path: "congres/actes/:id", component: ActsComponent },

  { path: "rd/main", component: RdMainComponent },
  { path: "rd/thematicFilter", component: FilterWithThematicComponent },

  { path: "espace_perso/accueil", component: MembershipMainComponent },
  { path: "espace_perso/annuaire", component: AnnuaireComponent },
  {
    path: "espace_perso/adhesion_personne_physique",
    component: AdhesionPhysiqueComponent,
  },
  {
    path: "espace_perso/adhesion_personne_morale",
    component: AdhesionMoraleComponent,
  },
  {
    path: "espace_perso/inscription_congres",
    component: CongressSignupComponent,
  },
  { path: "espace_perso/resume_congres", component: CongressResumeComponent },
  { path: "espace_perso/nous_aider", component: DonnationComponent },
  { path: "espace_perso/profil", component: ProfilComponent },
  {
    path: "espace_perso/mes_sections_de_reflexion",
    component: MyThinkSectionsListComponent,
  },
  {
    path: "espace_perso/mes_sections_de_reflexion/:id",
    component: MyThinkSectionDocsComponent,
  },

  {
    path: "articles/ressources/:tableName/:type/:method/:is_sfse/:is_past/:selectedIndex/:selectedItemIndex",
    component: PostsComponent,
  },
  {
    path: "article/ressources/:title/:tableName/:type/:method/:is_sfse/:is_past/:selectedIndex/:selectedItemIndex/:postId",
    component: PostDetailComponent,
  },
  {
    path: "articles/nosactions/:tableName/:type/:method/:selectedIndex/:selectedItemIndex",
    component: PostsActionsComponent,
  },
  {
    path: "article/nosactions/:title/:tableName/:type/:method/:selectedIndex/:selectedItemIndex/:postId",
    component: PostActionsDetailsComponent,
  },

  { path: "", redirectTo: "accueil", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
